<template>
  <!-- TODO: Vuetify -->
  <div
    class="card"
    @drop="onDrop($event, status)"
    @dragover.prevent
    @dragenter.prevent
  >
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200 table-auto">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID<br />Angebotsnr.
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Erstellt
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Artikel<br />Anwendungen
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Kunde<br />Kundengruppe
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                  >
                    Status
                    <br />
                    <status-header :status="status" />
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-center"
                  >
                    Kommentare
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-center"
                  >
                    Aktionen
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="item in this.requestsProp"
                  :key="item.id"
                  @click="$emit('openmodal', item)"
                  class="sm:cursor-pointer"
                  draggable="true"
                  @dragstart="startDrag($event, item)"
                >
                  <td class="px-6 py-4">
                    <div>
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.referrer && `${item.referrer} - `
                        }}{{ item.id }}
                      </div>
                      <div class="text-sm text-gray-500 text-left">
                        {{ item.anfrageid }}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ formatDate(item.createdAt) }}
                    </div>
                    <span v-if="item.eilt" class="text-red-600 font-bold"
                      >Eilt!</span
                    >
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-sm text-gray-900 mb-4 font-bold">
                      {{
                        `${item.name} ${parseInt(item.diameter)}-${
                          item.covering_width
                        }-${item.covering_height}-${item.height}-${
                          item.bohrung
                        } ${item.koernung !== undefined ? item.koernung : ""} ${
                          item.korn !== undefined ? item.korn : ""
                        } ${
                          item.concentration !== undefined
                            ? item.concentration
                            : ""
                        } ${
                          item.bondtype !== undefined
                            ? item.bond && item.korn
                              ? ""
                              : item.bondtype.split(" ")[0]
                            : ""
                        } ${item.bond !== undefined ? item.bond : ""}`
                      }}
                    </div>
                    <div class="text-sm text-gray-500 flex flex-wrap">
                      <span
                        class="bg-gray-100 text-gray-900 rounded pl-1 pr-1 mr-1"
                        v-for="anwendung in item.anwendungen"
                        :key="anwendung"
                      >
                        {{ anwendung }}
                      </span>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-sm text-gray-900">
                      <p title="Fritz Ibach Messer und Werkzeug GmbH">
                        {{ getCustomerName(item.customer.split("/")[3])[0] }}
                      </p>
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ getCustomerName(item.customer.split("/")[3])[1] }}
                    </div>
                  </td>
                  <td class="px-6 py-4 text-center">
                    <status-icon
                      :status="item.status"
                      :in-shop="item.a_in_shop"
                    />
                  </td>
                  <td class="text-center text-sm">
                    {{ getCommentCount(item.id) }}
                  </td>
                  <td class="text-center">
                    <!-- TODO: Replace dropdown menu with tailwind -->
                    <v-menu left bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="$emit('openmodal', item)"
                          :key="`edit${item.id}`"
                        >
                          Bearbeiten
                        </v-list-item>
                        <v-list-item>
                          <g-dialog
                            :key="`delete${item.id}`"
                            :request-id="item.id"
                            title="Löschen"
                            :allRequests="allRequests"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="loading" class="flex content-center p-4">
              <loading-state />
            </div>
            <div
              v-else-if="requestsProp.length === 0"
              class="flex content-center p-4"
            >
              <h2>Keine Einträge vorhanden</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from "@/components/LoadingState";
import GDialog from "@/components/Dialog";
import StatusHeader from "@/components/StatusHeader";
import StatusIcon from "@/components/StatusIcon";
import axios from "axios";

export default {
  name: "Card",
  components: {
    StatusIcon,
    GDialog,
    StatusHeader,
    LoadingState
  },
  methods: {
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    onDrop(evt, status) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.allRequests.find(item => {
        return item.id === parseInt(itemID);
      });

      if (
        item.status === status ||
        (status === 1 && item.status > 0 && item.status < 3)
      ) {
        alert("So wird das heute nichts...");
      } else {
        item.status = status;
        item.updatedAt = new Date();
        this.updateItem(item);
      }
    },
    updateItem(item) {
      axios
        .put(`${process.env.VUE_APP_API_URL}/abrasives_listings/${item.id}`, {
          ...item
        })
        .then(function(response) {
          console.log("SUBMITTED", response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getCommentCount(id) {
      const currentComments =
        this.comments &&
        this.comments.filter(comment => comment.requestId === id);

      const notReadComments = currentComments.filter(
        comment =>
          comment.readby && comment.readby.includes(this.$auth.user.name)
      );

      return currentComments.length - notReadComments.length;
    },
    getCustomerName(customernumber) {
      let firstname, customergroup;

      this.customers.map(customer => {
        if (customer.customernumber === customernumber) {
          firstname = customer.company
            ? customer.company
            : customer.firstname + " " + customer.lastname;
          customergroup = customer.customergroup;
        }
      });

      return [firstname, customergroup];
    }
  },
  props: {
    requestsProp: Array,
    loading: Boolean,
    customers: Array,
    abrasiveData: Object,
    allRequests: Array,
    status: Number,
    comments: Array
  }
};
</script>

<style lang="scss">
tbody {
  tr {
    &:nth-child(even) {
      @apply bg-gray-50;
    }

    &:hover {
      @apply bg-yellow-50;
    }
  }
}
</style>
