<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 100 100"
      class="animate-spin"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#00bc9b" />
          <stop offset="100%" stop-color="white" />
        </linearGradient>
      </defs>
      <circle
        cx="50"
        cy="50"
        r="47"
        stroke="url(#gradient)"
        stroke-width="6"
        fill="none"
        transform="rotate(90 50 50)"
      />
    </svg>
    Lade Daten...
  </div>
</template>

<script>
export default {
  name: "LoadingState"
};
</script>

<style scoped></style>
