import Vue from "vue";
import VueRouter from "vue-router";
import Requests from "@/views/Requests";
import RequestsNew from "@/views/RequestsNew";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Requests",
    component: Requests
  },
  {
    path: "/anfragen2",
    name: "RequestsNew",
    component: RequestsNew
  },
  {
    path: "/produkte",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products")
  },
  {
    path: "/medien",
    name: "Media",
    component: () => import(/* webpackChunkName: "media" */ "../views/Media")
  },
  {
    path: "/kunden",
    name: "Customers",
    component: () =>
      import(/* webpackChunkName: "customers" */ "../views/Customers")
  },
  {
    path: "/profil",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile")
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard")
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: () =>
      import(/* webpackChunkName: "calculator" */ "../views/Calculator")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings")
  }
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "bg-gray-900 text-white",
  routes
});

export default router;
