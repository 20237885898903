<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    @keyup.esc="$emit('closemodal')"
    tabindex="0"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
        @click="$emit('closemodal')"
      >
        <div
          class="absolute inset-0 bg-gray-900 opacity-75"
          style="-webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px)"
        ></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-6xl sm:max-w-full w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white">
          <div class="sm:flex sm:items-start">
            <div class="sm:text-left w-full">
              <div
                class="bg-gray-50 text-lg leading-6 font-medium text-gray-900 px-4 pt-5"
                id="modal-headline"
              >
                <div id="form-head" class="flex justify-between">
                  <div>
                    <p class="text-sm">
                      System-ID: {{ formData.id }}
                      <span v-if="formData.anfrageid"
                        >- {{ formData.anfrageid }}</span
                      >
                      <span v-if="formData.ordernumber">
                        <br />
                        Bestellnummer (Kunde): {{ formData.ordernumber }}
                      </span>
                    </p>
                    <p v-bind="formData">
                      <b
                        >{{ formData.name }}
                        {{ parseInt(formData.diameter) }}-{{
                          formData.covering_width
                        }}-{{ formData.covering_height }}-{{
                          formData.height
                        }}-{{ formData.bohrung }}</b
                      >
                    </p>
                  </div>
                  <div>
                    <small
                      >Erstellt: {{ formatDateTime(formData.createdAt) }}</small
                    >
                    <br />
                    <small
                      >Zuletzt bearbeitet:
                      {{ formatDateTime(formData.updatedAt) }}</small
                    >
                  </div>
                </div>
                <div class="tabs flex top-px relative text-sm">
                  <div
                    class="px-4 py-2 mr-2 rounded-t cursor-pointer"
                    tabindex="1"
                    :class="{
                      'border border-b-0 bg-white': firsttab,
                      'bg-gray-200 opacity-30': !firsttab
                    }"
                    @click="changeTab(1)"
                  >
                    Konfiguration
                  </div>
                  <div
                    class="px-4 py-2 mr-2 rounded-t cursor-pointer"
                    tabindex="2"
                    :class="{
                      'border border-b-0 bg-white': secondtab,
                      'bg-gray-200 opacity-30': !secondtab
                    }"
                    @click="changeTab(2)"
                  >
                    Kommentare
                    <span v-if="comments.count">{{ comments.count }}</span>
                  </div>
                  <div
                    class="px-4 py-2 mr-2 rounded-t cursor-pointer"
                    tabindex="3"
                    :class="{
                      'border border-b-0 bg-white': thirdtab,
                      'bg-gray-200 opacity-30': !thirdtab
                    }"
                    @click="changeTab(3)"
                  >
                    Artikel Kalkulation
                  </div>
                </div>
              </div>
              <div class="border-t" id="main-content">
                <edit-request
                  :formData="formData"
                  v-on="$listeners"
                  :abrasiveData="abrasiveData"
                  :customer="customer"
                  v-show="firsttab"
                />
                <div v-show="secondtab">
                  <comments
                    :request-id="formData.id"
                    @updateCommentsCount="updateCommentsCount"
                  />
                </div>
                <div v-show="thirdtab">
                  <calculation :form-data="formData" />
                </div>
              </div>
            </div>
            <div
              class="right-0 top-0 cursor-pointer justify-center flex items-center absolute bg-gray-100 hover:bg-gray-200 rounded-bl pr-1 pl-1 w-8 h-8"
              @click="$emit('closemodal')"
              title="Fenster schließen"
            >
              x
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditRequest from "../components/EditRequest.vue";
import Comments from "./tabs/Comments.vue";
import Calculation from "@/components/tabs/Calculation";

export default {
  name: "EditDialog",

  components: {
    Calculation,
    EditRequest,
    Comments
  },

  data() {
    return {
      firsttab: true,
      secondtab: false,
      thirdtab: false,
      comments: {
        count: 0
      }
    };
  },

  props: {
    formData: Object,
    abrasiveData: Object,
    customers: Array
  },

  computed: {
    customer: function() {
      let customernumber = "";

      this.customers.map(customer => {
        if (customer.customernumber === this.formData.customer.split("/")[3]) {
          customernumber = customer.customernumber;
        }
      });

      return customernumber;
    }
  },

  methods: {
    updateCommentsCount(count) {
      this.comments.count = count;
    },
    changeTab(index) {
      if (index === 1) {
        this.firsttab = true;
        this.secondtab = false;
        this.thirdtab = false;
      } else if (index === 2) {
        this.firsttab = false;
        this.secondtab = true;
        this.thirdtab = false;
      } else if (index === 3) {
        this.firsttab = false;
        this.secondtab = false;
        this.thirdtab = true;
      }
    }
  }
};
</script>
