<template>
  <div>
    <div
      class="grid sm:grid-cols-1 gap-2 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
      v-if="comments.length > 0"
    >
      <div
        v-for="comment in comments"
        :key="comment.id"
        :comments="comments"
        class="grid sm:grid-cols-1 gap-2"
      >
        <div class="flex justify-between">
          <p class="text-gray-400">{{ comment.email }} hat geschrieben:</p>
          <p class="text-gray-400">
            {{ formatDateTime(comment.createdAt) }}
            <span
              v-if="$store.state.admins.includes($auth.user.email)"
              class="cursor-pointer"
              @click="deleteComment(comment.id)"
              >X</span
            >
          </p>
        </div>
        <p>{{ comment.content }}</p>
        <hr class="mb-4" />
      </div>
    </div>
    <div v-else class="grid sm:grid-cols-1 gap-2 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      Keine Kommentare vorhanden
    </div>
    <new-comment :request-id="requestId" @updateComments="loadComments" />
  </div>
</template>

<script>
import NewComment from "@/components/tabs/NewComment";
import axios from "axios";

export default {
  components: { NewComment },
  props: {
    requestId: Number
  },
  data() {
    return {
      comments: []
    };
  },
  mounted() {
    this.loadComments();
  },
  methods: {
    deleteComment(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}/comments/${id}`).then(() => {
        this.comments = this.comments.filter(comment => comment.id !== id);
        this.$emit("updateCommentsCount", this.comments.length);
      });
    },
    loadComments() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/comments.json`)
        .then(res => {
          this.comments = res.data.filter(
            comment => comment.requestId === this.requestId
          );
          this.$emit("updateCommentsCount", this.comments.length);
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
