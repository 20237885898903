<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    @keyup.esc="$emit('closenewrequestmodal')"
    tabindex="0"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
        @click="$emit('closenewrequestmodal')"
      >
        <div
          class="absolute inset-0 bg-gray-900 opacity-75"
          style="-webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px)"
        ></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-6xl sm:max-w-full w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white">
          <div class="sm:flex sm:items-start">
            <div class="sm:text-left w-full">
              <div
                class="bg-gray-50 text-lg leading-2 font-medium text-gray-900 p-4"
              >
                Neue Anfrage
              </div>
              <div class="mt-2">
                <new-request-form
                  v-on="$listeners"
                  :abrasiveData="abrasiveData"
                  :customers="customers"
                />
              </div>
            </div>
            <div
              class="right-0 top-0 cursor-pointer justify-center flex items-center absolute bg-gray-100 hover:bg-gray-200 rounded-bl pr-1 pl-1 w-8 h-8"
              @click="$emit('closenewrequestmodal')"
              title="Fenster schließen"
            >
              x
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewRequestForm from "@/components/NewRequestForm";

export default {
  name: "NewRequest",
  components: { NewRequestForm },
  methods: {},
  props: {
    abrasiveData: Object,
    customers: Array
  }
};
</script>
