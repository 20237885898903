<template>
  <!-- TODO: Vuetify -->
  <v-app>
    <navigation :drawer="drawer" />

    <v-main style="background: #f6f7fb;">
      <app-bar @openDrawer="drawer = !drawer" v-if="$auth.isAuthenticated" />
      <v-container fluid class="mt-8 mb-8">
        <v-row>
          <router-view v-if="$auth.isAuthenticated" />
          <authentication-button v-if="!$auth.isAuthenticated" />
        </v-row>
      </v-container>
    </v-main>

    <v-footer padless color="white" v-if="$auth.isAuthenticated">
      <v-col class="text-center" cols="12">
        Update Intevall:
        {{ this.$store.state.updateIntervall / 1000 / 60 }} Minuten
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import Navigation from "@/components/Navigation";
import AuthenticationButton from "@/components/AuthenticationButton";

export default {
  components: {
    AuthenticationButton,
    Navigation,
    AppBar
  },
  data: () => ({
    drawer: true
  })
};
</script>
