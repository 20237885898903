<template>
  <div class="grid sm:grid-cols-1 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    <h2>Neuen Kommentar verfassen:</h2>
    <form
      class="grid sm:grid-cols-1 gap-2"
      id="comment"
      @submit.prevent="saveComment"
    >
      <textarea required v-model="comment.content" rows="4" />
      <button type="submit" class="border border-gray-400">
        Kommentar speichern
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewComment",
  data() {
    return {
      comment: {
        content: ""
      }
    };
  },
  methods: {
    async saveComment() {
      await axios
        .post(`${process.env.VUE_APP_API_URL}/comments.json`, {
          content: this.comment.content,
          createdAt: this.createdAt,
          email: this.$auth.user.name,
          requestId: this.requestId
        })
        .then(() => {
          document.querySelector("#comment").reset();
          this.$emit("updateComments");

          axios
            .post(
              `https://api.telegram.org/bot5073969887:AAFV3F5ONR0Cl2nXnPTz_y7a_2nJTYqtLqU/sendMessage?chat_id=-1001222752842&text=${this.$auth.user.name} schrieb zur Anfrage-Nr. ${this.requestId}:%0A${this.comment.content}&disable_notification=true&disable_web_page_preview=true`
            )
            .then(() => {
              alert("Das hat funktioniert");
            });
        })
        .catch(err => console.log(err));
    }
  },
  computed: {
    createdAt() {
      return new Date();
    }
  },
  props: {
    requestId: Number
  }
};
</script>
