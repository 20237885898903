const quadrat = (a, b, c) => a * b * c;

const prism = (grundflaeche, hoehe) => grundflaeche * hoehe;

const cylinder = (durchmesser, hoehe) =>
  3.14 * Math.sqrt(durchmesser / 2) * hoehe;

export default {
  quadrat,
  prism,
  cylinder
};
