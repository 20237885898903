import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notifications: { count: 0 },
    todos: [
      { id: 1, text: "...", done: true },
      { id: 2, text: "...", done: false }
    ],
    navigation: false,
    updateIntervall: 300000,
    admins: ["dmohr@gries-schleiftechnik.de", "mgries@gries-schleiftechnik.de"],
    settings: {
      aluminiumPrice: 0
    }
  },
  getters: {
    getTodoById: state => id => {
      return state.todos.find(todo => todo.id === id);
    }
  },
  mutations: {
    updateNotificationCount(state, n) {
      state.notifications.count = n;
    },
    updateSettings(state, n) {
      state.settings = n;
    }
  },
  actions: {
    updateNotificationCount({ commit }) {
      commit("updateNotificationCount");
    },
    updateSettings({ commit }) {
      commit("updateSettings");
    }
  },
  modules: {}
});
