<template>
  <!-- TODO: Vuetify -->
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          {{ title }}
        </v-list-item>
      </template>
      <v-card>
        <v-card-title class="headline">
          Anfrage löschen?
        </v-card-title>
        <v-card-text
          >Sind Sie sicher, dass Sie diese Anfrage unwiderruflich löschen
          wollen?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteRequest(requestId)">
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "Dialog",
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    deleteRequest(id) {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/abrasives_listings/${id}`)
        .then(response => {
          console.log("Request got deleted", response);
          const elemPos = this.allRequests.map(item => item.id).indexOf(id);
          this.allRequests.splice(elemPos, 1);
        })
        .catch(error => console.error(error));

      this.dialog = false;
    }
  },
  props: {
    requestId: Number,
    title: String,
    allRequests: Array
  }
};
</script>
