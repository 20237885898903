import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "@/auth/auth0-plugin";
import { config } from "dotenv";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import helpers from "@/plugins/helpers";
import router from "@/router";
import store from "@/store";
import "./registerServiceWorker";
import "@/index.scss";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

config();

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
      .then(r => console.log(r));
  }
});

Vue.config.productionTip = false;

Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.eventSetDrag = function() {
      el.setAttribute("data-dragging", "yes");
    };
    el.eventClearDrag = function() {
      el.removeAttribute("data-dragging");
    };
    el.eventOnClick = function(event) {
      const dragging = el.getAttribute("data-dragging");

      if (!(el === event.target || el.contains(event.target)) && !dragging) {
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener("touchstart", el.eventClearDrag);
    document.addEventListener("touchmove", el.eventSetDrag);
    document.addEventListener("click", el.eventOnClick);
    document.addEventListener("touchend", el.eventOnClick);
  },
  unbind: function(el) {
    document.removeEventListener("touchstart", el.eventClearDrag);
    document.removeEventListener("touchmove", el.eventSetDrag);
    document.removeEventListener("click", el.eventOnClick);
    document.removeEventListener("touchend", el.eventOnClick);
    el.removeAttribute("data-dragging");
  }
});

Vue.mixin({
  methods: {
    formatDate: date => {
      const newDate = new Date(date);
      return newDate.toLocaleDateString();
    },
    formatDateTime: datetime => {
      const newDate = new Date(datetime);
      return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()} Uhr`;
    }
  }
});

new Vue({
  store,
  vuetify,
  helpers,
  router,
  render: h => h(App)
}).$mount("#app");
