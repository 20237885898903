<template>
  <form
    id="a_request_edit_form"
    name="a_request_edit_form"
    @submit.prevent="checkForm"
    netlify
  >
    <div
      class="grid md:grid-cols-2 sm:grid-cols-1 gap-4 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
    >
      <div>
        <div class="grid md:grid-cols-2 gap-2 mb-4">
          <label>
            Angebotsnr. aus Jericho
            <input type="text" name="a_anfrageid" v-model="a_anfrageid" />
          </label>

          <label>
            Lieferanten Artikelnummer
            <input type="text" name="a_gries_id" v-model="a_gries_id" />
          </label>
        </div>

        <div class="grid grid-cols-1 mb-4">
          <label>
            Kunde *
            <select
              id="a_customers"
              v-model="a_customer_selected"
              v-bind="a_customers"
            >
              <option value=""></option>
              <option
                v-for="(a_customer, index) in a_customers"
                :value="a_customer.customernumber"
                :name="a_customer.firstname"
                :key="index"
              >
                {{ a_customer.company }}
              </option>
            </select>
          </label>
        </div>

        <div class="grid grid-flow-row mb-4">
          <label>
            Eilt!
            <input type="checkbox" v-model="eilt" class="mb-4" />
          </label>

          <label>
            Anwendungen *
            <multiselect
              v-model="anwendungen.value"
              name="a_anwendungen"
              tag-placeholder="Diese neue Anwendung hinzufügen"
              placeholder="Anwendungsbereiche"
              track-by="name"
              label="name"
              :options="anwendungen.options"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
            />
          </label>
        </div>

        <div class="mb-4">
          <label>
            Kundenkommentar
            <textarea
              rows="5"
              name="a_kommentar"
              id="a_kommentar"
              v-model="a_kommentar"
              placeholder="Kommentar des Kunden"
            />
          </label>
        </div>

        <div class="mb-4">
          <label>
            Preis
            <input type="text" name="a_price" id="a_price" v-model="a_price" />
          </label>
        </div>

        <div class="mb-4">
          <file-upload :request-id="formData.id" />
        </div>
      </div>

      <div>
        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Form *
            <select id="a_forms" v-model="a_form_selected" v-bind="a_forms">
              <option value=""></option>
              <option
                v-for="(a_form, index) in forms_active"
                :value="a_form.name"
                :name="a_form.name"
                :key="index"
              >
                {{ a_form.name }}
              </option>
            </select>
          </label>

          <div
            class="flex align-end flex-1 mb-4 ml-2"
            v-if="a_form_selected.hasAngle"
          >
            <label>
              Belagwinkel *
              <input
                id="a_angle"
                v-model="a_angle"
                type="number"
                min="0"
                name="a_angle"
                placeholder="Belagwinkel"
              />
            </label>
          </div>

          <label>
            Durchmesser *
            <select
              id="a_durchmesser"
              v-model="a_durchmesser_selected"
              v-bind="a_durchmesser"
            >
              <option value=""></option>
              <option
                v-for="(a_durchmesser_single, index) in diameters_active"
                :value="a_durchmesser_single.name"
                :name="a_durchmesser_single.name"
                :key="index"
              >
                {{ a_durchmesser_single.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Belagbreite *
            <input
              type="text"
              name="a_belagbreite"
              id="a_belagbreite"
              v-model="a_belagbreite"
            />
          </label>

          <label>
            Belaghöhe *
            <input
              type="text"
              name="a_belaghoehe"
              id="a_belaghoehe"
              v-model="a_belaghoehe"
            />
          </label>
        </div>

        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Gesamthöhe *
            <input
              type="text"
              name="a_gesamthoehe"
              id="a_gesamthoehe"
              v-model="a_gesamthoehe"
            />
          </label>

          <label>
            Bohrung *
            <input
              type="text"
              name="a_bohrung"
              id="a_bohrung"
              v-model="a_bohrung"
            />
          </label>
        </div>

        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Schleifkorntyp
            <select
              id="a_abrasivetype"
              v-model="a_abrasivetype_selected"
              v-bind="a_abrasivetypes"
              @change="getKoernungen"
            >
              <option value=""></option>
              <option
                v-for="(a_abrasivetype, index) in a_abrasivetypes"
                :value="a_abrasivetype.name"
                :name="a_abrasivetype.name"
                :key="index"
              >
                {{ a_abrasivetype.name }}
              </option>
            </select>
          </label>

          <label>
            Konzentration
            <select
              id="a_concentrations"
              v-model="a_concentration_selected"
              v-bind="a_concentrations"
            >
              <option value=""></option>
              <option
                v-for="(a_concentration, index) in concentrations_active"
                :value="a_concentration.name"
                :name="a_concentration.name"
                :key="index"
              >
                {{ a_concentration.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Bindungstyp *
            <select
              id="a_bondtypes"
              v-model="a_bondtype_selected"
              v-bind="a_bondtypes"
            >
              <option value=""></option>
              <option
                v-for="(a_bondtype, index) in a_bondtypes"
                :value="a_bondtype.name"
                :name="a_bondtype.name"
                :key="index"
              >
                {{ a_bondtype.name }}
              </option>
            </select>
          </label>

          <label>
            Bindung (MG)
            <select id="a_bonds" v-model="a_bond_selected" v-bind="a_bonds">
              <option value=""></option>
              <option
                v-for="(a_bond, index) in bond_active"
                :value="a_bond.name"
                :name="a_bond.name"
                :key="index"
              >
                {{ a_bond.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="grid grid-cols-2 gap-2 mb-4">
          <label>
            Korn (MG)
            <select id="a_korns" v-model="a_korn_selected" v-bind="a_korns">
              <option value=""></option>
              <option
                v-for="(a_korn, index) in korn_active"
                :value="a_korn.griesBezeichnung"
                :name="a_korn.griesBezeichnung"
                :key="index"
              >
                {{ a_korn.griesBezeichnung }} - {{ a_korn.name }}
              </option>
            </select>
          </label>

          <label>
            Körnung
            <select
              id="a_koernungen"
              v-model="a_koernung_selected"
              v-bind="a_koernungen"
            >
              <option value=""></option>
              <option
                v-for="(a_koernung, index) in koernung_active"
                :value="a_koernung.name"
                :name="a_koernung.name"
                :key="index"
              >
                {{ a_koernung.name }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </div>

    <div
      class="border-t border-gray-100 px-4 py-3 sm:px-6 sm:flex justify-between"
    >
      <div class="flex align-center">
        <button
          :disabled="
            !(
              a_form_selected &&
              a_durchmesser_selected &&
              a_belagbreite &&
              a_belaghoehe &&
              a_gesamthoehe &&
              a_bohrung &&
              a_concentration_selected &&
              a_bondtype_selected &&
              a_bond_selected &&
              a_korn_selected &&
              a_koernung_selected &&
              a_price
            ) || a_in_shop
          "
          class="active:bg-blue-400 active:hover:bg-blue-600 active:text-white opacity-20 active:opacity-100 font-bold uppercase py-2 px-4 rounded ring-1 ring-gray-800"
          @click="createProduct"
        >
          {{
            !a_in_shop
              ? "Artikel im Shop einstellen"
              : `Artikel bereits im Shop: ${shopid}`
          }}
        </button>
        <button
          class="hover:bg-red-50 text-red-400 hover:text-red-600 ring-1 ring-red-400 font-bold uppercase py-2 px-4 rounded m-2"
          :disabled="a_status_selected !== 1"
          @click="freigabeMG"
        >
          {{
            a_status_selected > 1
              ? "Vom Verkauf freigegeben"
              : "Für MG freigeben"
          }}
        </button>
      </div>

      <div>
        <button
          @click="$emit('closemodal')"
          class="hover:bg-red-50 border-red-600 text-red-400 hover:text-red-600 ring-red-400 ring-1 font-bold uppercase py-2 px-4 rounded m-2"
        >
          Abbrechen
        </button>
        <button
          type="submit"
          class="bg-green-400 hover:bg-green-600 text-white ring-1 ring-green-400 font-bold uppercase py-2 px-4 rounded"
        >
          Speichern
        </button>
      </div>
    </div>

    <!-- TODO: Vuetify -->
    <v-alert
      v-if="showSuccessAlert"
      transition="scale-transition"
      origin="center center"
      type="success"
    >
      Das hat funktioniert!
    </v-alert>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import FileUpload from "@/components/FileUpload";

export default {
  name: "Forms",

  components: {
    Multiselect,
    FileUpload
  },

  data() {
    return {
      anwendungen: {
        value: [],
        options: []
      },
      showSuccessAlert: false,
      a_anwendungen: this.abrasiveData.anwendungen,
      a_forms: this.abrasiveData.formen,
      a_form_selected: this.formData.name,
      a_durchmesser: this.abrasiveData.durchmesser,
      a_durchmesser_selected: parseInt(this.formData.diameter),
      a_belagbreite: this.formData.coveringWidth,
      a_belaghoehe: this.formData.coveringHeight,
      a_gesamthoehe: this.formData.height,
      a_status_selected: this.formData.status,
      a_anfrageid: this.formData.anfrageid,
      a_bohrung: this.formData.bohrung,
      a_concentration_selected: this.formData.concentration,
      a_concentrations: this.abrasiveData.konzentrationen,
      a_bondtypes: this.abrasiveData.bindungstypen,
      a_bonds: this.abrasiveData.bindungen,
      a_bond_selected: this.formData.bond,
      a_bondtype_selected: this.formData.bondtype,
      a_abrasivetype_selected: this.formData.abrasivetype,
      a_abrasivetypes: this.abrasiveData.schleifkorntypen,
      a_kommentar: this.formData.kommentar,
      a_referrer: this.formData.referrer,
      a_price: this.formData.price,
      a_customers: this.abrasiveData.kunden,
      a_customer_selected: this.customer,
      a_quality: this.formData.quality,
      a_korn_selected: this.formData.korn,
      a_korns: this.abrasiveData.korn,
      a_koernung_selected: this.formData.koernung,
      a_koernungen: this.abrasiveData.koernungen,
      a_files_selected: this.formData.files,
      files: [],
      a_in_shop: this.formData.in_shop,
      shopid: `103${this.formData.id}-SD`,
      a_gries_id: this.formData.supplierProductId,
      a_angle: "",
      a_radius: "",
      koernungen: this.abrasiveData.koernungen,
      korn: this.abrasiveData.korn,
      eilt: this.formData.eilt
    };
  },
  computed: {
    product_name() {
      return `${this.a_form_selected} ${this.a_durchmesser_selected}-${this.a_belagbreite}-${this.a_belaghoehe}-${this.a_gesamthoehe}-${this.a_bohrung} ${this.a_koernung_selected} ${this.a_concentration_selected} ${this.a_bondtype_selected}`;
    },
    product_url() {
      return `https://schleifmitteldirekt.de/${this.a_form_selected}-${
        this.a_durchmesser_selected
      }-${this.a_belagbreite}-${this.a_belaghoehe}-${this.a_gesamthoehe}-${
        this.a_bohrung.split("/")[0]
      }-${this.a_bohrung.split("/")[1]}-${this.a_koernung_selected}-${
        this.a_concentration_selected
      }-${this.a_bondtype_selected}/`;
    },
    customer_email() {
      const customer = this.a_customers.filter(
        customer => customer.customernumber === this.a_customer_selected
      );

      return customer[0].email;
    },
    customer_id() {
      const customer = this.a_customers.filter(
        customer => customer.customernumber === this.a_customer_selected
      );

      return customer[0].customernumber;
    },
    concentrations_active() {
      return this.a_concentrations.filter(conc => conc.active);
    },
    koernung_active() {
      return this.a_koernungen.filter(koernung => koernung.active);
    },
    forms_active() {
      return this.a_forms.filter(form => form.active);
    },
    diameters_active() {
      return this.a_durchmesser.filter(diameter => diameter.active);
    },
    bond_active() {
      return this.a_bonds.filter(bond => bond.active);
    },
    korn_active() {
      return this.a_korns.filter(korn => korn.active);
    }
  },
  mounted() {
    this.assignAnwendungenToOptions();
    this.assignAnwendungenToValues();
    this.a_files_selected && this.getFilename();
    this.getKoernungen();
  },
  methods: {
    freigabeMG() {
      this.a_status_selected = 2;
      this.addTask();
      this.onSubmit();
    },
    async postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer f303a72e885d80fe255d229559bec2b22dc59945"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    },
    addTask() {
      console.log("Task added");
      this.bodydata = {
        content: "Neue Anfrage",
        project_id: 2270451251,
        description: `${this.productname}`,
        assignee: 7987603
      };
      // MG: 7987603
      // DM: 9688040
      this.postData(
        "https://api.todoist.com/rest/v1/tasks",
        this.bodydata
      ).then(data => {
        console.log(data);
      });
    },
    getKoernungen() {
      if (this.a_abrasivetype_selected === "Diamant") {
        this.a_koernungen = this.koernungen.filter(
          koernung => koernung.schleifkorntyp === "/api/schleifkorntypens/1"
        );

        this.a_korns = this.korn.filter(korn => korn.typ === "Diamant");
      } else if (this.a_abrasivetype_selected === "CBN") {
        this.a_koernungen = this.koernungen.filter(
          koernung => koernung.schleifkorntyp === "/api/schleifkorntypens/2"
        );

        this.a_korns = this.korn.filter(korn => korn.typ === "CBN");
      }
    },
    async getFilename() {
      await this.a_files_selected.map(file => {
        this.files.push(`${process.env.VUE_APP_BASE_URL}/media/${file}`);
      });
    },
    async createProduct() {
      const product = {
        name: this.product_name,
        productNumber: `103${this.formData.id}-SD`,
        stock: 0,
        taxId: "a141d35f9ca9426a96f92e25d86da4e0",
        price: [
          {
            currencyId: "b7d2554b0ce847cd82f3ac9bd1c0dfca",
            gross: this.a_price * 1.19,
            net: this.a_price,
            linked: false
          }
        ],
        deliveryTimeId: "bdabbc42de3b46a7bbbd5300f549395d",
        isNew: true,
        keywords: [],
        description: "Test Beschreibung",
        categories: [{ id: "e0634f5134cd44abb74aa10ddb0ba340" }],
        manufacturerId: "bbd4efa70afe4f578011651b457822b4",
        manufacturerNumber: this.a_gries_id,
        visibilities: [
          {
            id: "a141d35f9ca9426a96f92e25d86da4e0",
            salesChannelId: "4d432d55754c4661ae81a5bd2a119e98",
            visibility: 30
          }
        ]
      };

      let tokenRequest = await axios.post(
        `${process.env.VUE_APP_SHOP_URL}/api/oauth/token`,
        {
          grant_type: "client_credentials",
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET
        }
      );

      let currentBearerToken = `Bearer ${tokenRequest.data.access_token}`;

      await axios
        .post(`${process.env.VUE_APP_SHOP_URL}/api/v3/product`, product, {
          headers: {
            Authorization: currentBearerToken
          }
        })
        .then(() => {
          this.showSuccessAlert = true;
          this.updateShopStatus(this.formData.id, true);
          this.sendEmailNotification();
          // this.onSubmit();
        })
        .catch(error => alert(`${error}`));
    },
    async sendEmailNotification() {
      await axios
        .post(`${process.env.VUE_APP_API_URL}/email`, {
          id: this.customer_id,
          productname: this.product_name,
          price: this.a_price,
          shopid: this.shopid,
          url: this.product_url,
          recipient: this.customer_email
        })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.error(err);
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag
      };
      this.anwendungen.options.push(tag);
      this.anwendungen.value.push(tag);
    },
    assignAnwendungenToValues() {
      const anwArr = [];

      this.formData.anwendungen.map(anwendung => {
        anwArr.push({ name: anwendung });
      });

      this.anwendungen.value = anwArr;
    },
    assignAnwendungenToOptions() {
      this.anwendungen.options = this.abrasiveData.anwendungen;
    },
    updateShopStatus(id, status) {
      const now = new Date();

      axios
        .put(`${process.env.VUE_APP_API_URL}/abrasives_listings/${id}`, {
          inShop: status,
          updated_at: now
        })
        .then(() => {
          this.a_in_shop = status;
          this.startAlertTimer();
        });
    },
    startAlertTimer() {
      this.showSuccessAlert = true;
      setTimeout(() => (this.showSuccessAlert = false), 3000);
    },
    async onSubmit() {
      const now = new Date();
      const vm = this;

      if (
        this.a_form_selected &&
        this.a_durchmesser_selected &&
        this.a_belagbreite &&
        this.a_belaghoehe &&
        this.a_gesamthoehe &&
        this.a_abrasivetype_selected &&
        this.a_concentration_selected &&
        this.a_korn_selected &&
        this.a_bond_selected &&
        this.a_bondtype_selected
      ) {
        this.a_status_selected = 3;
      }

      if (this.a_in_shop) {
        this.a_status_selected = 4;
      }

      const anwendungen = [];
      Object.values(this.anwendungen.value).map(anwendung =>
        anwendungen.push(anwendung.name)
      );

      await axios
        .put(
          `${process.env.VUE_APP_API_URL}/abrasives_listings/${this.formData.id}`,
          {
            anfrageid: this.a_anfrageid,
            name: this.a_form_selected,
            diameter: this.a_durchmesser_selected.toString(),
            coveringWidth: parseInt(this.a_belagbreite),
            coveringHeight: parseInt(this.a_belaghoehe),
            height: parseInt(this.a_gesamthoehe),
            updatedAt: now,
            status: parseInt(
              this.a_status_selected === 0 ? 1 : this.a_status_selected
            ),
            bohrung: this.a_bohrung,
            concentration: this.a_concentration_selected,
            bondtype: this.a_bondtype_selected,
            bond: this.a_bond_selected,
            abrasivetype: this.a_abrasivetype_selected,
            kommentar: this.a_kommentar,
            price: parseFloat(this.a_price),
            korn: this.a_korn_selected,
            koernung: this.a_koernung_selected,
            anwendungen: anwendungen,
            customer: `/api/customers/${this.customer_id}`,
            supplierProductId: this.a_gries_id,
            eilt: this.eilt
          }
        )
        .then(function(response) {
          vm.$emit("closemodal", response);
          vm.$emit("updateFormData");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    checkForm() {
      if (this.a_form_selected && this.a_durchmesser) {
        this.onSubmit();
      }

      this.errors = [];

      if (!this.a_form_selected) {
        this.errors.push("Bitte wählen Sie eine Form.");
      }

      if (!this.a_durchmesser) {
        this.errors.push("Der Durchmesser ist erforderlich.");
      }
    }
  },
  props: {
    formData: Object,
    abrasiveData: Object,
    customer: String
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
