<template>
  <div class="grid sm:grid-cols-1 gap-2 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    <h2>
      Hier wird der Artikel automatisch kalkuliert und diese Kalkulation
      hinterlegt
    </h2>

    <p>Erste Vorbereitungen auf den Kalkulationsprozess</p>

    <p>
      Test Kalkulation: {{ volumen }} (Durchmesser x Gesamthöhe x Belagbreite)
    </p>
    <p>Aluminium Preis x 2: {{ $store.state.settings.aluminiumPrice * 2 }} €</p>
  </div>
</template>

<script>
import volume_calculation from "@/helper/volume_calculation";

export default {
  name: "Calculation",
  computed: {
    volumen() {
      return volume_calculation.quadrat(
        this.formData.diameter,
        this.formData.height,
        this.formData.coveringWidth
      );
    }
  },
  props: {
    formData: Object
  }
};
</script>

<style scoped></style>
