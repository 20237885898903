<template>
  <div
    class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 col-12"
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-12 w-auto"
          src="@/assets/logo.svg"
          alt="Gries Schleiftechnik GmbH & Co. KG Logo"
        />
        <img
          class="mx-auto h-12 w-4/6"
          src="@/assets/sd_logo.svg"
          alt="SD Schleifmitteldirekt GmbH Logo"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold">
          Anfrage Center Anmeldung
        </h2>
      </div>

      <login-button />
    </div>
  </div>
</template>

<script>
import LoginButton from "@/components/auth/LoginButton";

export default {
  name: "AuthenticationButton",
  components: { LoginButton }
};
</script>
