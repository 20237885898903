<template>
  <!-- TODO: Vuetify -->
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    color="#292c31"
    v-if="$auth.isAuthenticated"
  >
    <logo />

    <v-divider></v-divider>

    <nav>
      <router-link
        v-for="navitem in accessRoutes"
        :key="navitem.title"
        class="flex px-4 py-1 items-center"
        :to="navitem.link"
        exact-active-class="active border-indigo-500 text-gray-900 focus:border-indigo-700"
        :title="navitem.title"
      >
        <v-list-item-icon>
          <v-icon>{{ navitem.icon }}</v-icon>
        </v-list-item-icon>

        {{ navitem.title }}
      </router-link>
    </nav>

    <template v-slot:append>
      <div class="pa-2">
        <logout-button />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import LogoutButton from "@/components/auth/LogoutButton";
import Logo from "@/components/Logo";

export default {
  name: "Navigation",
  components: {
    LogoutButton,
    Logo
  },
  data() {
    return {
      navitems: [
        {
          title: "Anfragen",
          icon: "mdi-pen",
          link: "/",
          access: [
            "dmohr@gries-schleiftechnik.de",
            "mgries@gries-schleiftechnik.de",
            "verkauf@gries-schleiftechnik.de"
          ]
        },
        {
          title: "Anfragen 2",
          icon: "mdi-pen",
          link: "/anfragen2",
          access: ["dmohr@gries-schleiftechnik.de"]
        },
        {
          title: "Dashboard",
          icon: "mdi-arrow-up",
          link: "/dashboard",
          access: [
            "dmohr@gries-schleiftechnik.de",
            "mgries@gries-schleiftechnik.de",
            "verkauf@gries-schleiftechnik.de"
          ]
        },
        {
          title: "Artikel",
          icon: "mdi-cart",
          link: "/produkte",
          access: [
            "dmohr@gries-schleiftechnik.de",
            "mgries@gries-schleiftechnik.de",
            "verkauf@gries-schleiftechnik.de"
          ]
        },
        {
          title: "Kunden",
          icon: "mdi-account",
          link: "/kunden",
          access: [
            "dmohr@gries-schleiftechnik.de",
            "mgries@gries-schleiftechnik.de",
            "verkauf@gries-schleiftechnik.de"
          ]
        },
        {
          title: "Dateibrowser",
          icon: "mdi-file",
          link: "/medien",
          access: [
            "dmohr@gries-schleiftechnik.de",
            "mgries@gries-schleiftechnik.de",
            "verkauf@gries-schleiftechnik.de"
          ]
        },
        {
          title: "Kalkulator",
          icon: "mdi-calculator",
          link: "/calculator",
          access: ["dmohr@gries-schleiftechnik.de"]
        },
        {
          title: "Einstellungen",
          icon: "mdi-wrench",
          link: "/settings",
          access: ["dmohr@gries-schleiftechnik.de"]
        }
      ]
    };
  },
  computed: {
    accessRoutes() {
      return this.navitems.filter(navitem =>
        navitem.access.includes(this.$auth.user.email)
      );
    }
  },
  props: {
    drawer: Boolean
  }
};
</script>

<style lang="scss">
a {
  &.active {
    color: goldenrod !important;
    background-color: darken(goldenrod, 40);
  }

  color: lightslategray !important;
}
</style>
