import Vue from "vue";

import volumeCalculation from "@/helper/volume_calculation";

export default {
  install: () => {
    Vue.prototype.volumeCalculation = volumeCalculation;
    Vue.volumeCalculation = volumeCalculation;
  }
};
