<template>
  <!-- TODO: Vuetify -->
  <v-app-bar color="white" flat fixed app>
    <v-app-bar-nav-icon @click="$emit('openDrawer')"></v-app-bar-nav-icon>

    <h1>Anfragen Center</h1>

    <v-spacer></v-spacer>

    <span class="cursor-pointer mr-4">
      <icon-search />
    </span>

    <button class="mr-4">
      <icon-bell />
      <span class="absolute object-right-top -mr-6 top-0" v-if="notifications">
        <span
          class="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white"
        >
          {{ notifications }}
        </span>
      </span>
    </button>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item to="/support">
          <v-icon color="black mr-2" small>
            mdi-help
          </v-icon>
          Hilfe / Support
        </v-list-item>
        <a
          href="https://gries-api-rcyf9.ondigitalocean.app/admin"
          target="_blank"
        >
          <v-list-item>
            <v-icon color="black" small>
              mdi-hammer-wrench
            </v-icon>
            Admin
          </v-list-item>
        </a>
      </v-list>
    </v-menu>

    <router-link to="/profil" active-class="">
      <img :src="$auth.user.picture" :alt="$auth.user.name" width="40" />
    </router-link>
  </v-app-bar>
</template>

<script>
import IconBell from "@/components/icons/IconBell";
import IconSearch from "@/components/icons/IconSearch";

export default {
  name: "AppBar",
  components: {
    IconBell,
    IconSearch
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.count;
    }
  }
};
</script>

<style scoped lang="scss">
img {
  border-radius: 50%;
  margin-left: 1rem;
}

a {
  background: transparent;

  i {
    margin-right: 0.5rem;
  }
}
</style>
