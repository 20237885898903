<template>
  <!-- TODO: Vuetify -->
  <v-btn
    block
    color="#fab252"
    v-if="$auth.isAuthenticated"
    @click.prevent="logout"
    light
  >
    Logout
  </v-btn>
</template>

<script>
export default {
  name: "LogoutButton",
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    }
  }
};
</script>
