<template>
  <router-link to="/">
    <img
      class="px-4 py-4 w-7/12"
      src="@/assets/logo.svg"
      alt="Gries Schleiftechnik GmbH & Co. KG Logo"
    />
  </router-link>
</template>

<script>
export default {
  name: "Logo"
};
</script>
