<template>
  <!-- TODO: Vuetify -->
  <div class="w-full">
    <edit-dialog
      v-if="showModal"
      @closemodal="showModal = false"
      :formData="formData"
      @updateFormData="updateFormData"
      :customers="abrasiveData.kunden"
      :abrasive-data="abrasiveData"
    />

    <new-request
      v-if="showNewRequestModal"
      @closenewrequestmodal="showNewRequestModal = false"
      :customers="abrasiveData.kunden"
      :abrasive-data="abrasiveData"
      @openNewRequestModal="openNewRequestModal"
      @updateFormData="updateFormData"
    />

    <div class="flex justify-end">
      <input
        type="text"
        class="rounded border border-gray-500 shadow mb-4 mx-4"
        v-model="search"
        placeholder="In Anfragen suchen"
      />
    </div>
    <div class="mb-4 shadow-md" v-if="search.length > 0">
      <h2 class="p-4 bg-white rounded-t">
        Gefundene Anfragen
      </h2>
      <card
        @openmodal="addDataToForm"
        :requestsProp="foundRequests"
        :abrasiveData="abrasiveData"
        :customers="abrasiveData.kunden"
        :comments="comments"
      />
    </div>

    <div class="mb-4 shadow-md" v-show="!search">
      <div
        class="bg-white flex items-center pl-4 py-2 pr-2 rounded-t justify-between"
      >
        <h2>Aktuelle Anfragen</h2>
        <div>
          <v-btn
            outlined
            @click="refreshData"
            :disabled="disabled"
            id="refresh-btn"
          >
            <icon-refresh />
          </v-btn>
          <v-btn class="ma-2" outlined color="green" @click="createNewRequest">
            Anfrage erstellen
          </v-btn>
        </div>
      </div>
      <card
        @openmodal="addDataToForm"
        :requestsProp="newRequests"
        :loading="requests.length === 0"
        :abrasiveData="abrasiveData"
        :all-requests="requests"
        :customers="abrasiveData.kunden"
        :status="1"
        :comments="comments"
      />
    </div>

    <div class="shadow-md" v-show="!search">
      <div
        @click="openDoneRequests = !openDoneRequests"
        class="bg-white cursor-pointer flex items-center p-4 "
        :class="{ rounded: !openDoneRequests, 'rounded-t': openDoneRequests }"
      >
        Erledigte Anfragen
        <v-spacer />
        <div>
          <icon-up-arrow v-if="openDoneRequests" />
          <icon-down-arrow v-else />
        </div>
      </div>
      <div :class="{ activeToggle: !openDoneRequests }">
        <card
          @openmodal="addDataToForm"
          :requestsProp="doneRequests"
          :loading="requests.length === 0"
          :abrasiveData="abrasiveData"
          :all-requests="requests"
          :customers="abrasiveData.kunden"
          :status="4"
          :comments="comments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import EditDialog from "@/components/EditDialog.vue";
import NewRequest from "@/components/NewRequest.vue";
import IconDownArrow from "../components/icons/IconDownArrow";
import IconUpArrow from "../components/icons/IconUpArrow";
import IconRefresh from "@/components/icons/IconRefresh";
import axios from "axios";

export default {
  name: "Home",
  components: {
    IconRefresh,
    IconUpArrow,
    IconDownArrow,
    NewRequest,
    Card,
    EditDialog
  },
  data: () => {
    return {
      search: "",
      openDoneRequests: false,
      showModal: false,
      showNewRequestModal: false,
      formData: null,
      requests: [],
      disabled: false,
      comments: [],
      abrasiveData: {
        formen: null,
        durchmesser: null,
        schleifkorntypen: null,
        bindungstypen: null,
        koernungen: null,
        konzentrationen: null,
        kunden: [],
        anwendungen: [],
        files: []
      },
      notificationCount: 0
    };
  },
  created() {
    if (localStorage.settings) {
      this.$store.commit("updateSettings", JSON.parse(localStorage.settings));
    }
  },
  mounted() {
    this.updateFormData();
    this.loadAbrasiveData();

    setInterval(() => {
      this.refreshData();
    }, this.$store.state.updateIntervall);
  },
  methods: {
    refreshData() {
      this.disabled = true;
      this.updateFormData();
      this.$emit("rehydrate data");
      setTimeout(() => {
        this.disabled = false;
      }, 3000);
    },
    createNewRequest() {
      this.showNewRequestModal = true;
    },
    loadAbrasiveData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/abrasive_forms.json`)
        .then(response => {
          this.abrasiveData.formen = response.data;
        })
        .catch(error => {
          console.error(error);
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/diameters.json`)
        .then(response => {
          this.abrasiveData.durchmesser = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/bindungs.json`)
        .then(response => {
          this.abrasiveData.bindungen = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/schleifkorntypens.json`)
        .then(response => {
          this.abrasiveData.schleifkorntypen = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/bindungstyps.json`)
        .then(response => {
          this.abrasiveData.bindungstypen = response.data;
        });

      axios.get(`${process.env.VUE_APP_API_URL}/korns.json`).then(response => {
        this.abrasiveData.korn = response.data;
      });

      axios
        .get(`${process.env.VUE_APP_API_URL}/koernungs.json`)
        .then(response => {
          this.abrasiveData.koernungen = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/concentrations.json`)
        .then(response => {
          this.abrasiveData.konzentrationen = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/anwendungens.json`)
        .then(response => {
          this.abrasiveData.anwendungen = response.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/media_objects.json`)
        .then(response => {
          this.abrasiveData.files = response.data;
        });
    },
    updateFormData() {
      this.loadData();
      this.$store.commit("updateNotificationCount", this.notTouchedRequests);
      this.notificationCount = this.$store.state.notifications.count;
    },
    addDataToForm(e) {
      this.formData = e;
      this.showModal = true;
    },
    openNewRequestModal() {
      this.showNewRequestModal = true;
    },
    async loadData() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/customers.json`)
        .then(res => {
          this.abrasiveData.kunden = res.data;
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}/comments.json`)
        .then(response => {
          this.comments = response.data;
        });

      await axios
        .get(`${process.env.VUE_APP_API_URL}/abrasives_listings.json`)
        .then(res => {
          this.requests = res.data;
        })
        .catch(err => console.error(err));

      this.$store.commit("updateNotificationCount", this.notTouchedRequests);
      this.notificationCount = this.$store.state.notifications.count;
    }
  },
  computed: {
    getCustomerName(customernumber) {
      let firstname, customergroup;

      this.abrasiveData.kunden.map(customer => {
        if (customer.customernumber === customernumber) {
          firstname = customer.company
            ? customer.company
            : customer.firstname + " " + customer.lastname;
          customergroup = customer.customergroup;
        }
      });

      return [firstname, customergroup];
    },
    foundRequests() {
      return (
        this.requests != null &&
        this.requests
          .filter(requestItem => {
            const arrItem = Object.values(requestItem);
            return (
              arrItem
                .join(" ")
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) > -1
            );
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    },
    doneRequests() {
      return (
        this.requests != null &&
        this.requests
          .filter(requestItem => requestItem.status === 4)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    },
    newRequests() {
      return (
        this.requests != null &&
        this.requests
          .filter(requestItem => requestItem.status < 4)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    },
    notTouchedRequests() {
      return (
        this.requests != null &&
        this.requests
          .filter(requestItem => requestItem.status === 0)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).length
      );
    }
  }
};
</script>

<style lang="scss">
.activeToggle {
  .card {
    div {
      max-height: 0;
    }
  }
}

.new-shadow {
  box-shadow: 0 0 1px 0 rgba(23, 52, 110, 0.08),
    4px 8px 24px 0 rgba(31, 46, 77, 0.16);
}
</style>
