<template>
  <small
    ><span
      v-if="status === 1"
      style="background: dodgerblue; color: white; padding: .1rem .2rem; border-radius: 2px; margin-right: 0.1rem"
      >Neu</span
    >
    <span
      v-if="status === 1"
      style="background: goldenrod; color: white; padding: .1rem .2rem; border-radius: 2px; margin-right: 0.1rem"
      >Bearbeitung</span
    >
    <span
      v-if="status === 1"
      style="background: crimson; color: white; padding: .1rem .2rem; border-radius: 2px;"
      >Qualität</span
    >
    <span
      v-if="status === 4"
      style="background: mediumseagreen; color: white; padding: .1rem .2rem; border-radius: 2px;"
      >Erledigt</span
    ></small
  >
</template>

<script>
export default {
  name: "StatusHeader",
  props: {
    status: Number
  }
};
</script>

<style scoped></style>
